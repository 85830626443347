<template>
  <div>
    <Header></Header>
    <!-- CONTACT FORM
        ================================================== -->
    <section v-if="formContainer" class="overflow-visible" style="padding-top: 10px;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 wow fadeIn" data-wow-delay="400ms">
            <div class="primary-shadow bg-white p-1-6 p-sm-2-9 rounded z-index-9 position-relative">
              <h2 class="mb-1-9 groteskUltra text-secondary">Aplica Aquí</h2>
              <span>Llena los datos a continuación...</span>
              <form class="contact quform" id="gf-form" name="gf-form"  method="post" @submit.prevent="submitForm">
                <div class="quform-elements">
                  <div class="row">

                    <div class="col-md-6">
                      <div class="quform-element form-group">
                        <label for="fullName">Nombre Completo <span class="quform-required">*</span></label>
                        <div class="quform-input">
                          <input class="form-control" id="fullName" type="fullName" name="name" placeholder="" v-model="fullName" required/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="quform-element form-group">
                        <label for="workplace">Lugar de Trabajo <span class="quform-required">*</span></label>
                        <div class="quform-input">
                          <input class="form-control" id="workplace" type="text" name="workplace" placeholder="" v-model="workplace" required/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="quform-element form-group">
                        <label for="age">Edad <span class="quform-required">*</span></label>
                        <div class="quform-input">
                          <input class="form-control" id="age" type="number" name="age" placeholder="" v-model="age" required/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="quform-element form-group">
                        <label for="personalId">Cédula <span class="quform-required">*</span></label>
                        <div class="quform-input">
                          <input class="form-control" id="personalId" type="text" name="personalId" placeholder="" v-model="personalId" required/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="quform-element form-group">
                        <label for="contactPhone">Celular / Teléfono <span class="quform-required">*</span></label>
                        <div class="quform-input">
                          <input class="form-control" id="contactPhone" type="text" name="phone" placeholder="" v-model="contactPhone" required/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="quform-element form-group">
                        <label for="email">Correo Electrónico (Opcional)</label>
                        <div class="quform-input">
                          <input class="form-control" id="email" type="email" name="email" v-model="email" placeholder=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="quform-element form-group">
                        <label for="comments">Comentarios</label>
                        <div class="quform-input">
                          <textarea class="form-control h-100" id="comments" name="comments" rows="3" v-model="comments" placeholder="Indicanos aquí si tiene alguna consulta.."></textarea>
                        </div>
                      </div>
                      <i class="small"><i class="fa fa-info-circle"></i> Su información no será compartida.</i>
                    </div>
                    <div class="col-md-12 mt-2">
                      <div v-if="!recaptcha && errorMessage">
                        <div class="errorMessage"><i class="fa fa-exclamation-triangle"></i> {{ errorMessage }}</div>
                      </div>
                      <div class="quform-submit-inner">
                        <vue-recaptcha sitekey="6LfjsisdAAAAAGQ58gLMuZJU19DEZp9nUQbfMWDp" @verify="reVerify"></vue-recaptcha>
                    </div>
                      </div>
                    <div class="col-md-12 mt-2">
                      <div class="quform-submit-inner">
                        <button class="butn-style1" type="submit">Enviar</button>
                       </div>
                      <div class="quform-loading-wrap text-start"><span class="quform-loading"></span></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>


<!--    // Success-->

    <section v-if="success" class="overflow-visible" style="padding-top: 10px;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 wow fadeIn" data-wow-delay="400ms">
            <div class="primary-shadow bg-white p-1-6 p-sm-2-9 rounded z-index-9 position-relative">
              <h2 class="mb-1-9 groteskUltra text-success">Su información ha sido enviada correctamente.</h2>
              <h5 class="mb-1-9 groteskUltra text-secondary">Estaremos en contacto con usted muy prontamente.</h5>
              <a :href="$router.resolve({name: 'Home'}).href">
                <button class="btn btn-primary d-block mt-4 text-light">Regresar</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

<!--    -->
<!--    Error-->

<!--    <section v-if="error" class="overflow-visible" style="padding-top: 10px;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 wow fadeIn" data-wow-delay="400ms">
            <div class="primary-shadow bg-white p-1-6 p-sm-2-9 rounded z-index-9 position-relative">
              <span class="text-danger"><i class="fa fa-exclamation-triangle"></i> Error</span>
              <h4 class="mb-1-9 groteskUltra text-danger">Su información no fue envidada..</h4>
              <span class="mb-1-9 groteskUltra text-secondary">Revise su conexión a internet e inténtelo nuevamente.</span>
              <a href="gf-form.html">
                <button class="btn btn-primary d-block mt-4 text-light">Regresar</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>-->



    <!-- CONTACT INFO
    ================================================== -->
    <section class="bg-light-gray">
      <div class="container">
        <div class="title-style3 text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
          <span>Contacto</span>
          <h2 class="mb-0 h1">Estamos aquí para ayudarte</h2>
        </div>
        <div class="row mt-n4">
          <div class="col-md-6 col-xl-4 mt-4 wow fadeInUp" data-wow-delay="200ms">
            <div class="card card-style10 border-0 border-radius-10 ms-4 h-100">
              <div class="card-heading mt-4 position-relative">
                <h3 class="h5 mb-0 text-white">Ubicación</h3>
                <i class="icon-map display-7 text-white opacity2 position-absolute top-n10 end-0"></i>
              </div>
              <div class="card-body p-1-9">
                <p class="mb-0">Avenida Central al lado de la Basílica Don Bosco.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4 mt-4 wow fadeInUp" data-wow-delay="400ms">
            <div class="card card-style10 border-0 border-radius-10 ms-4 h-100">
              <div class="card-heading mt-4 position-relative">
                <h3 class="h5 mb-0 text-white">Teléfonos</h3>
                <i class="icon-mobile display-7 text-white opacity2 position-absolute top-n10 end-0"></i>
              </div>
              <div class="card-body p-1-9">
                <p class="mb-1">+507 6746-7906</p>
                <p class="mb-0">+507 202-6800 / 6900</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-4 mt-4 wow fadeInUp" data-wow-delay="600ms">
            <div class="card card-style10 border-0 border-radius-10 ms-4 h-100">
              <div class="card-heading mt-4 position-relative">
                <h3 class="h5 mb-0 text-white">Correo Electrónico</h3>
                <i class="icon-chat display-7 text-white opacity2 position-absolute top-n10 end-0"></i>
              </div>
              <div class="card-body p-1-9">
                <p class="mb-1">info@grupofuturo.net</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- CONTACT MAP
    ================================================== -->
    <iframe class="map" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDYwYz4FWbqyUEPBAS-UkuXLtEeEEk3QpY&q=8.970431988034466,-79.5380173496095&zoom=18"
    >
    </iframe>
    <Footer></Footer>
  </div>

</template>

<script>
import Header from "../components/Header"
import Footer from "../components/Footer"
import {projectFirestore} from "@/firebase/config";
import VueRecaptcha from 'vue-recaptcha';


export default {
  name: "GFForm.vue",
  components: {
    Header,
    Footer,
    VueRecaptcha,
  },
  data() {
    return {
      fullName:'',
      workplace: '',
      age: '',
      personalId: '',
      contactPhone: '',
      email: '',
      comments: '',
      sitekey: '6LfniB4dAAAAAFmG-qGoFWzidekbagohXgjYFjVG',

      error: false,
      success: false,
      formContainer: true,
      recaptcha: null,

      errorMessage: null,
      created_at: null,

    }
  },
  created(){
    window.onpopstate = function () {
      location.reload()
    };
  },
  methods:{
    reVerify( response ) {
      this.recaptcha = response
    },
    submitForm(){
      if (this.recaptcha){
      let lead = {
        fullName: this.fullName,
        workplace: this.workplace,
        age: this.age,
        personalId: this.personalId,
        contactPhone: this.contactPhone,
        email: this.email,
        comments: this.comments,
        createdAt: projectFirestore.serverTimestamp,
             }

      projectFirestore.collection("leads")
                .add({
            to: 'adrianamartucci@gmail.com',
            template: {
              name: "template1",
              data: {
                fullName: this.fullName,
                workplace: this.workplace,
                age: this.age,
                personalId: this.personalId,
                contactPhone: this.contactPhone,
                email: this.email,
                comments: this.comments,

      },
            },
              lead,
          })
          .then(() =>{
            this.success = true
            this.formContainer = false
            this.error = false
            window.location=document.getElementById('!').href;
          })
          .catch(()=>{
            this.error = true
            this.formContainer = false
      })
    }else {
        this.errorMessage = 'Verifique que usted no es un robot'
      }}
  }
}
</script>



<style scoped>
.errorMessage{
  font-size: .8em;
  color: red;
  margin-bottom: 10px;
}
</style>
