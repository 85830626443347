import firebase from 'firebase/compat';
import 'firebase/firestore'


const firebaseConfig = {
    apiKey: "AIzaSyAnkeJWLaCqeTbDYvoC1pwKOmV3PGg_Yrk",
    authDomain: "grupofuturo-web.firebaseapp.com",
    projectId: "grupofuturo-web",
    storageBucket: "grupofuturo-web.appspot.com",
    messagingSenderId: "1097753938338",
    appId: "1:1097753938338:web:8f438f33d3a7361cb24afb",
    measurementId: "G-877R8SNDSR"
};


firebase.initializeApp(firebaseConfig)


// init firestore

const projectFirestore = firebase.firestore()
projectFirestore.serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

export {
    projectFirestore,
}
