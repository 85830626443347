<template>
<div>
  <Header></Header>
  <section class="md">
    <div class="container">
      <div class="row justify-content-center mb-2-9 mb-lg-6">
        <div class="col-lg-12">
          <div class="bg-img rounded p-2-3 p-sm-6 p-md-7 p-xl-10 cover-background mb-2-9 wow fadeIn" data-wow-delay="200ms" style="background-image: url('img/bg/company-header.jpg')">
            <div class="backScreen mb-3"><span class="mb-1-9 text-white w-lg-55 text-shadow-large display-22">Nos Apasiona lo que hacemos.</span></div>
            <a href="contact.html" class="butn-style1 secondary medium text-white">Ponte en Contacto con Nosotros</a>
          </div>
          <div class="wow fadeIn" data-wow-delay="200ms">
            <h2 class="mb-3">Nosotros</h2>
            <p class="w-lg-95">Los primeros como empresa dedicada a la promoción de
              productos bancarios en Panamá.
              Nos constituimos para brindar asesoramiento financiero profesional a los
              clientes y guiarlos a las mejores instituciones financieras del país, logrando
              así con nuestra fuerza de ventas incrementar sustancialmente las carteras
              crediticias de los bancos y financieras asociados a grupo Futuro.
              Estamos haciendo una gran inversión ya que nuestra meta es liderizar el
              mercado panameño. </p>
            <p class="w-lg-95 mb-0">Nuestro interés a corto plazo es contar con
              presencia permanente de nuestros
              distintos asociados en nuestras oficinas, lo que nos permitirá dar un
              servicio rápido y confiable.
              Contamos con oficinas ubicadas estratégicamente en avenida central a un
              costado de la Basílica Don Bosco y próximamente con sucursales en interior del país.</p>
          </div>
        </div>
      </div>
      <div class="row align-items-xl-center">
        <div class="col-lg-6 mb-2-3 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
          <div class="pe-lg-5">
            <img src="img/bg/company-structure.jpg" alt="...">
          </div>
        </div>
        <div class="col-lg-6 wow fadeIn" data-wow-delay="400ms">
          <div class="ps-xl-5">
            <div id="accordion" class="accordion-style1">
              <div class="card mb-3">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Estructura Administrativa
                    </button>
                  </h5>
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                  <div class="card-body">
                    Contamos con el siguiente personal:
                    Gerente General, GerenteAdministrativo, Gerente de Ventas.
                    Departamento de Mercadeo, Departamento de Operaciones,
                    Departamento de Contabilidad, Atención al Cliente Pre Venta y Pos Venta
                    lo que nos permite una operıción ágily dinámica con constante
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Plan de negocios
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                  <div class="card-body">
                    Nuestro plan constituye en armar oficinas en todas las provincias y
                    abarcar todo el territorio nacional.
                    Nuestro objetivo es colocar préstamos por el monto de 10 millones de
                    dólares mensuales.
                    Nuestro record en el pasado fue de 33 millones en un mes cuando
                    poseíamos la exclusividad.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Beneficios para su Banco
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                  <div class="card-body">
                    Nuestros promotores utilizan nuestra maquinaria administrativa para
                    centralizar todos los procesos desde una sola fuente.
                    Constante entrenamiento y capacitación para lograr Cerradores, honestos,
                    rápidos y con total conocimiento de las políiticas bancarias y financieras
                    Captadores de calle, preparados para ofrecer nuestros productos a los
                    miles de clientes potenciales.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer></Footer>
</div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "CorpInfo.vue",
  components: {
    Header,
    Footer,
  }
}
</script>

<style scoped>

</style>
